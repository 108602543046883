import Layout from "../components/Layout";
import { GithubIcon, MailIcon } from "../components/SocialIcons";
import mustache from "../assets/mustache.png";
import { SimpleLayout } from "../components/SimpleLayout";

const socialIcons = [
  {
    name: "Github Icon",
    icon: GithubIcon,
    href: "https://github.com/NathanielHotchkiss",
    follow: "Follow me on Github",
  },
];

export default function Home() {
  return (
    <Layout>
      <div className="grid grid-cols-1 gap-y-16 lg:grid-cols-2 lg:grid-rows-[auto_1fr] lg:gap-y-12 ">
        <div className="lg:pl-20">
          <div className="max-w-xs px-2.5 lg:max-w-none">
            <img
              src={mustache}
              alt=""
              sizes="(min-width: 1024px) 32rem, 20rem"
              className="aspect-square rotate-3 rounded-2xl bg-zinc-100 object-cover dark:bg-zinc-800"
            />
          </div>
        </div>
        <div className="lg:order-first lg:row-span-2">
          <SimpleLayout
            title="
            I’m Nathan Hotchkiss."
          >
            <p className="mt-4">
              I'm a web developer living in Northern Virginia. Software has
              always fascinated me so I started learning how to code a few years
              ago for fun. I wanted to know more about web development and how
              to make good-looking reliable websites.
            </p>
            <p className="mt-4">
              Like many web developers, my journey started by learning how to
              lay out websites with HTML, then style them with CSS. Soon, I was
              looking at what comes next. That's when a friend of mine told me
              about Thinkful.
            </p>

            <p className="mt-4">
              Thinkful is a coding bootcamp that focuses on building modern web
              applications and websites. The program lasted 20 weeks and covered
              JavaScript, React, Bootstrap, Node, Express, and Postgres. It
              taught you how to think like a developer with pair programming,
              version control, unit testing, data structures, and deploying
              applications. I loved this experience and learned everything that
              I need to know to be a successful web developer.
            </p>

            <p className="mt-4">
              Today I am building new projects, putting everything that I've
              learned over the last few years to work. This website may look
              simple, and there are certainly better ones out there, but I woke
              up one day and turned a blank screen into this!
            </p>

            <p className="mt-4">
              Please check out my Fishing Journal project, this is my passion
              project. It's everything I've learned wrapped into an application
              that I use and care about.
            </p>

            <p className="mt-4">
              I'm actively looking for an opportunity to work as a Web Developer
              with a company.
            </p>
          </SimpleLayout>
        </div>
        <div className="lg:pl-20">
          <ul>
            {socialIcons.map((social) => {
              const { name, href, follow } = social;

              return (
                <li className="mb-3" key={name}>
                  <a
                    href={href}
                    alt={name}
                    className="group flex text-sm font-medium text-zinc-800 transition hover:text-zinc-600 dark:text-zinc-300 dark:hover:text-zinc-200"
                  >
                    <social.icon />
                    <span className="ml-4">{follow}</span>
                  </a>
                </li>
              );
            })}
            <li className="flex mt-8 border-t border-zinc-100 pt-8 dark:border-zinc-700/40">
              <a
                href="mailto:hotchkisswork@gmail.com"
                alt="Reach me at hotchkisswork@gmail.com"
                className="group flex text-sm font-medium text-zinc-800 transition hover:text-zinc-600 dark:text-zinc-300 dark:hover:text-zinc-100"
              >
                <MailIcon />
                <span className="ml-4">hotchkisswork@gmail.com</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </Layout>
  );
}
