import Layout from "../components/Layout.jsx";
import { SimpleLayout } from "../components/SimpleLayout";
import {
  UserGroupIcon,
  FilmIcon,
  RectangleStackIcon,
  ServerIcon,
  ArrowTopRightOnSquareIcon,
  PresentationChartBarIcon,
  BookOpenIcon,
} from "@heroicons/react/24/outline";
import { GithubIcon } from "../components/SocialIcons";

const projectsData = [
  {
    title: "Fishing Journal - frontend",
    description:
      "Create an account, capture fishing data, upload images using AWS S3.",
    icon: BookOpenIcon,
    url: "https://fishing-journal-frontend.vercel.app",
    repo: "https://github.com/NathanielHotchkiss/Fishing-frontend",
  },
  {
    title: "Fishing Journal - backend",
    description:
      "PosgreSQL, Express API with user authentication and login functionality.",
    icon: ServerIcon,
    url: null,
    repo: "https://github.com/NathanielHotchkiss/Fishing-server",
  },
  {
    title: "Figma Wireframes",
    description:
      "Transformed hand drawn plans into a 12 page web application with Figma.",
    icon: PresentationChartBarIcon,
    url: null,
    repo: "https://github.com/NathanielHotchkiss/Wireframes",
  },
  {
    title: "Restaurant Reservations",
    description:
      "Full-stack React and Express application focused on a reservation system for a restaurant.",
    icon: UserGroupIcon,
    url: "https://hotchkiss-reservations.vercel.app",
    repo: "https://github.com/NathanielHotchkiss/Periodic-Tables",
  },
  {
    title: "WeLoveMovies",
    description:
      "Express API that delivers movies, theaters showing the movie, and reviews related to the movie.",
    icon: FilmIcon,
    url: null,
    repo: "https://github.com/NathanielHotchkiss/WeLoveMovies-server",
  },
  {
    title: "React Flashcards",
    description:
      "Flashcards for studying, with the ability to create, edit, and delete cards or decks.",
    icon: RectangleStackIcon,
    url: null,
    repo: "https://github.com/NathanielHotchkiss/React-Flashcards",
  },
];

const liveSiteButton = (url) => {
  if (url) {
    return (
      <a
        href={url}
        target="_blank"
        rel="noopener noreferrer"
        className="flex text-sm text-zinc-800 dark:text-zinc-200 group-hover:text-zinc-900 dark:group-hover:text-zinc-200"
      >
        <ArrowTopRightOnSquareIcon className="h-5 w-5 text-zinc-800 dark:text-zinc-400 group-hover:text-zinc-900 dark:group-hover:text-zinc-200" />
        <span className="mx-2">{url}</span>
      </a>
    );
  } else {
    return null;
  }
};

export default function Projects() {
  return (
    <Layout>
      <SimpleLayout
        title="A few of my favorite projects."
        intro="Here are just a few of the projects I've completed. Check out the source code for each one on Github, more information on each project can be found in the README files."
      ></SimpleLayout>

      <div className="grid grid-cols-1 gap-x-12 gap-y-16 lg:grid-cols-2 xl:grid-cols-3">
        {projectsData.map((project) => {
          const { title, description, url, repo } = project;

          return (
            <div
              className="group block w-full rounded-lg bg-white hover:bg-zinc-100 dark:bg-zinc-900 dark:hover:bg-zinc-800 space-y-3 p-6"
              key={title}
              href={repo}
            >
              <div className="relative z-10 flex h-10 w-10 items-center justify-center rounded-full bg-zinc-200 dark:bg-zinc-300 dark:group-hover:bg-zinc-100 dark:group-hover:shadow-md shadow-zinc-800/5 dark:border dark:border-zinc-800/50 ring-1 ring-zinc-900/5 dark:ring-0 mb-4">
                <project.icon
                  className="h-6 w-6 fill-zinc-200 dark:fill-zinc-300 transition group-hover:fill-zinc-100 dark:group-hover:fill-zinc-100"
                  aria-hidden="true"
                />
              </div>
              <h3 className="text-base font-semibold text-zinc-800 dark:text-zinc-100 group-hover:text-zinc-900 dark:group-hover:text-zinc-200 tracking-tight">
                {title}
              </h3>
              <p className="text-sm text-zinc-600 dark:text-zinc-400 group-hover:text-zinc-900 dark:group-hover:text-zinc-200 h-16">
                {description}
              </p>

              <div>
                <a
                  href={repo}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex text-sm text-zinc-800 dark:text-zinc-200 group-hover:text-zinc-900 dark:group-hover:text-zinc-200"
                >
                  <GithubIcon className="h-5 w-5 text-zinc-800 dark:text-zinc-400 group-hover:text-zinc-900 dark:group-hover:text-zinc-200" />
                  <span className="mx-1">github.com</span>
                </a>
              </div>
              <div>{liveSiteButton(url)}</div>
            </div>
          );
        })}
      </div>
    </Layout>
  );
}
