import React from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";

export default function Layout({ children }) {
  return (
    <>
      <div className="min-h-screen sm:mx-28 bg-white ring-1 ring-zinc-100 dark:bg-zinc-900 dark:ring-zinc-300/20">
        <Navbar />
        <div className="px-6 sm:px-24 my-16 sm:my-24">{children}</div>
        <Footer />
      </div>
    </>
  );
}
