export default function Footer() {
  return (
    <div className="text-center border-t border-zinc-200 dark:border-zinc-700 shadow-sm px-10 py-8 mx-12">
      <span className="text-sm text-zinc-800 dark:text-zinc-400 ">
        &copy; {new Date().getFullYear()} - developed by{" "}
        <a
          className="hover:underline underline-offset-4"
          href="https://github.com/NathanielHotchkiss"
          rel="noopener noreferrer"
          target="_blank"
        >
          Nathaniel Hotchkiss
        </a>
      </span>
    </div>
  );
}
