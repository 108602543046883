import { SimpleLayout } from "../components/SimpleLayout";
import Layout from "../components/Layout";
import { Section } from "../components/Section";

function ToolsSection({ children, ...props }) {
  return (
    <Section {...props}>
      <ul className="space-y-16">{children}</ul>
    </Section>
  );
}

function Tool({ title, children }) {
  return (
    <li className="mb-4">
      <h3 className="text-base font-semibold tracking-tight text-zinc-800 dark:text-zinc-100">
        {title}
      </h3>
      <p className="relative z-10 mt-2 text-sm text-zinc-500 dark:text-zinc-400">
        {children}
      </p>
    </li>
  );
}

export default function Uses() {
  return (
    <Layout>
      <SimpleLayout
        title="Techologies I use."
        intro="Heres a list of the technologies I use to create websites."
      >
        <div className="space-y-20 my-20">
          <ToolsSection title="Setup">
            <Tool title="M1 Macbook Pro">
              Enjoying all of the MacOS Ventura features!
            </Tool>
          </ToolsSection>
          <ToolsSection title="Front-end">
            <ul className="relative z-10 mt-2 text-sm text-zinc-600 dark:text-zinc-400">
              <Tool title="HTML & CSS">
                Mobile first development, forms, flexbox.
              </Tool>
              <Tool title="JavaScript">
                ES6, Promises, Async & Await, Error handling, Test Driven
                Development.
              </Tool>
              <Tool title="React">
                Reusable components, React-router, React-hooks.
              </Tool>
              <Tool title="Bootstrap">
                Great for quickly adding responsiveness to applications.
              </Tool>
              <Tool title="Tailwindcss">
                Similiar to Bootstrap, beautiful pre-built components, and great
                docs.
              </Tool>
            </ul>
          </ToolsSection>

          <ToolsSection title="Back-end">
            <ul className="relative z-10 mt-2 text-sm text-zinc-600 dark:text-zinc-400">
              <Tool title="Node & Express">
                RESTful API's, authentication, middleware, routes.
              </Tool>
              <Tool title="PostgreSQL">
                Insert, query, update and delete data, create tables.
              </Tool>
            </ul>
          </ToolsSection>

          <Section title="Tools & Skills">
            <ul className="relative z-10 mt-2 text-sm text-zinc-600 dark:text-zinc-400">
              <Tool title="Figma">Design website wireframes.</Tool>
              <Tool title="git & github">
                Version control system for tracking changes.
              </Tool>
              <Tool title="Visual Studio Code">
                Lots of great features like embedded git and lots of extension
                options.
              </Tool>
              <Tool title="Postico2">
                A powerful database application for PostgreSQL.
              </Tool>
              <Tool title="Postman">
                Helpful for testing and iterating API's.
              </Tool>
              <Tool title="Heroku">Great for deploying API's.</Tool>
              <Tool title="Vercel">
                Platform for deploying frontend frameworks and static sites.
              </Tool>
            </ul>
          </Section>
        </div>
      </SimpleLayout>
    </Layout>
  );
}
