import { useContext } from "react";
import { ThemeContext } from "../utils/ThemeContext";
import { MoonIcon, SunIcon } from "@heroicons/react/24/outline";

export default function Toggle() {
  const { theme, setTheme } = useContext(ThemeContext);

  return (
    <div className="absolute right-6 top-4">
      {theme === "dark" ? (
        <button
          onClick={() => setTheme("light")}
          className="inline-flex items-center rounded-lg border border-zinc-300 bg-zinc-200/50 dark:bg-zinc-700 px-2 py-1 shadow-sm hover:bg-zinc-50 dark:hover:bg-zinc-500 focus:outline-none focus:ring-2 focus:ring-zinc-500 focus:ring-offset-2"
        >
          <SunIcon className="text-zinc-900 dark:text-zinc-200 h-5 w-5" />
        </button>
      ) : (
        <button
          onClick={() => setTheme("dark")}
          className="inline-flex items-center rounded-lg border border-zinc-300 bg-zinc-200/50 dark:bg-zinc-700 px-2 py-1 shadow-sm hover:bg-zinc-50 dark:hover:bg-zinc-500 focus:outline-none focus:ring-2 focus:ring-zinc-500 focus:ring-offset-2"
        >
          <MoonIcon className="text-zinc-900 dark:text-zinc-200 h-5 w-5" />
        </button>
      )}
    </div>
  );
}
