import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Projects from "./pages/Projects";
import Uses from "./pages/Uses";
import NotFound from "./components/NotFound";

import { ThemeProvider } from "./utils/ThemeContext";
import Background from "./components/Background";
import Toggle from "./components/ThemeToggle";

export default function App() {
  return (
    <ThemeProvider>
      <Background>
        <Router>
          <Toggle />
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route path="/projects" element={<Projects />} />
            <Route path="/uses" element={<Uses />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Router>
      </Background>
    </ThemeProvider>
  );
}
